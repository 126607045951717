


































































































































import Vue from "vue";
import CarreraService, { GetResultadoCarrera } from "@/api/CarreraService";
export default Vue.extend({
  props: ["distanciaID", "genero", "categoriaID"],
  data() {
    return {
      loading: false,
      search: "",
      resultados: [] as GetResultadoCarrera[],
      headers: [
        { text: "#", value: "lugar" },
        //{ text: "Camiseta", value: "numeroCamiseta" },
        { text: "Camiseta", value: "chipCronometraje.codigo" },
        { text: "Chip", value: "chipCronometraje.codigo" },
        { text: "Nombre", value: "persona.displayNombreCompleto" },
        /*
        {
          text: "Identificación",
          value: "persona.identificacion",
          align: "d-none"
        },
        */
        //{ text: "guntime", value: "guntime" },
        { text: "Chiptime", value: "chiptime" },
        { text: "Pace", value: "ritmo" }
      ]
    };
  },
  methods: {
    loadResultados() {
      this.loading = true;
      const params = new URLSearchParams({
        distancia: this.distanciaID,
        genero: this.genero
      });
      if (this.categoriaID) {
        params.append("categoria", this.categoriaID);
      }
      CarreraService.getResultadosCarrera(
        this.$route.params.id,
        params.toString()
      )
        .then(response => {
          this.resultados = response;
        })
        .catch(() => {
          console.log("no se pudo cargar la carrera");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    customFilter(value: any, search: any, item: any) {
      return (
        search &&
        value &&
        typeof value === "string" &&
        (String(item.persona.identificacion).indexOf(search.toUpperCase()) !==
          -1 ||
          String(item.persona.displayNombreCompleto).indexOf(
            search.toUpperCase()
          ) !== -1)
      );

      //return true;
    }
    // fin methods
  },
  mounted() {
    this.loadResultados();
  }
});
