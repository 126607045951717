<template>
  <div>
    <v-img :src="imagenBanner" width="100%" />
    <v-container class="mt-8">
      <v-row>
        <!-- banner 1 -->
        <v-col cols="12" v-if="imagenBanner1 && imagenBanner1.src">
          <a :href="imagenBanner1.url" target="_blank" v-if="imagenBanner1.url">
            <v-img :src="imagenBanner1.src"></v-img>
          </a>
          <v-img :src="imagenBanner1.src" v-else></v-img>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="px-6" cols="12" lg="6" xl="6" sm="12">
          <!--
          <v-img contain width="100%" :src="main" />
          -->
          <v-img contain height="500px" :src="foto" />
        </v-col>

        <v-col class="px-6" cols="12" lg="6" xl="6" sm="12">
          <v-row class="pt-8">
            <v-avatar tile size="36">
              <v-img :src="icons.run" contain />
            </v-avatar>
            <v-col class="pt-0">
              <div class="title-item"><b>Carrera:</b></div>
              <div>{{ card.nombre }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-5 pt-3">
            <v-avatar tile size="36">
              <v-img class="pd" :src="icons.distancia" contain />
            </v-avatar>
            <v-col class="pt-0">
              <div class="title-item"><b>Distancias:</b></div>
              <div>{{ distanciasJoin }}</div>
            </v-col>
          </v-row>
          <v-row class="mt-5 pt-3">
            <v-avatar tile size="36">
              <v-img class="pd py-1" :src="icons.ubicacion" contain />
            </v-avatar>
            <v-col class="pt-0">
              <div>
                <span class="title-item"><b>Ciudad:</b> </span>
                {{ toCamelCase(card.ciudad) }}
              </div>
              <div>
                <span class="title-item"><b>Lugar:</b> </span>
                {{ card.lugar }}
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-5 pt-3">
            <v-avatar tile size="36">
              <v-img class="pd py-1" :src="icons.calendario" contain />
            </v-avatar>
            <v-col class="pt-0">
              <div>
                <span class="title-item"><b>Inicio:</b> </span>
                {{ formatDate(card.fechaInicioEvento) }}
              </div>
              <div>
                <span class="title-item"><b>Fin:</b> </span>
                {{ formatDate(card.fechaFinEvento) }}
              </div>
              <!--
              <div>
                <span class="title-item">Hora: </span>
                {{ res.horaInicioEvento.toFixed(2).replace(".", ":") }}
              </div>
              -->
            </v-col>
          </v-row>
          <v-row class="pt-4">
            <v-avatar tile size="36">
              <v-img class="p-8" :src="icons.hashtag" contain />
            </v-avatar>
            <v-col class="pt-0">
              <div>
                <span class="title-item"><b>Hashtag Oficial:</b> </span>
                {{ card.hashtag }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="imagenMiniBanner && imagenMiniBanner.src">
              <v-img :src="imagenMiniBanner.src" width="100%"></v-img>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-btn text color="#5a897d" class="my-8">
              <v-icon>mdi-share</v-icon>
              COMPARTIR
            </v-btn>
            <v-btn
              :href="share('ws')"
              target="_blank"
              icon
              color="#5a897d"
              class="my-8"
            >
              <img :src="icons.ws" />
            </v-btn>
            <v-btn
              :href="share('fb')"
              target="_blank"
              icon
              color="#5a897d"
              class="my-8"
            >
              <img :src="icons.fb" />
            </v-btn>
            <!--
            <v-btn icon color="#5a897d" class="my-8">
              <img :src="icons.in" />
            </v-btn>
            -->
            <v-btn
              :href="
                'mailto:?subject=Carrera en Keep Running&amp;body=' +
                  share('em')
              "
              target="_blank"
              icon
              color="#5a897d"
              class="my-8"
            >
              <img :src="icons.mail" />
            </v-btn>
          </v-row>
        </v-col>

        <!-- banner 2 -->
        <v-col cols="12" v-if="imagenBanner2 && imagenBanner2.src">
          <a :href="imagenBanner2.url" target="_blank" v-if="imagenBanner2.url">
            <v-img :src="imagenBanner2.src"></v-img>
          </a>
          <v-img :src="imagenBanner2.src" v-else></v-img>
        </v-col>

        <!-- resultados -->
        <v-col cols="12">
          <Resultados :distancias="carrera.distancias" />
        </v-col>

        <!-- banner 3 -->
        <v-col cols="12" v-if="imagenBanner3 && imagenBanner3.src">
          <a :href="imagenBanner3.url" target="_blank" v-if="imagenBanner3.url">
            <v-img :src="imagenBanner3.src"></v-img>
          </a>
          <v-img :src="imagenBanner3.src" v-else></v-img>
        </v-col>

        <!-- galeria -->
        <v-col cols="12">
          <GaleriaCarrera />
        </v-col>
        <v-col cols="12" v-if="organizadasBanner1 && organizadasBanner1.src">
          <a
            :href="organizadasBanner1.url"
            target="_blank"
            v-if="organizadasBanner1.url"
          >
            <v-img :src="organizadasBanner1.src"></v-img>
          </a>
          <v-img :src="organizadasBanner1.src" v-else></v-img>
        </v-col>

        <!-- banner 4 -->
        <v-col cols="12" v-if="imagenBanner4 && imagenBanner4.src">
          <a :href="imagenBanner4.url" target="_blank" v-if="imagenBanner4.url">
            <v-img :src="imagenBanner4.src"></v-img>
          </a>
          <v-img :src="imagenBanner4.src" v-else></v-img>
        </v-col>

        <v-col cols="12">
          <v-row justify="center">
            <v-col
              v-for="(card, index) in auspiciantes"
              :key="index"
              cols="4"
              sm="3"
              class="my-auto"
            >
              <a :href="card.url" target="_blank" v-if="card.url">
                <v-img
                  :src="getAuspicianteImageUrl(card.fileName)"
                  class="white--text align-end"
                  contain
                >
                </v-img>
              </a>
              <v-img
                :src="getAuspicianteImageUrl(card.fileName)"
                class="white--text align-end"
                contain
                v-else
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Resultados from "@/components/web/organizadas/Resultados.vue";
import GaleriaCarrera from "@/components/web/organizadas/GaleriaCarrera.vue";
import moment from "moment";
import CartStore from "@/store/modules/CartStore";
import AuthStore from "@/store/modules/AuthStore";
import CarreraService from "@/api/CarreraService";
import BannerPublicitarioCarreraService from "@/api/carrera/detalle/BannerPublicitarioCarreraService";
import StringUtils from "@/utils/StringUtils";
import DateUtils from "@/utils/DateUtils";
import AuspicianteCarreraService from "@/api/carrera/detalle/AuspicianteCarreraService";
export default {
  components: {
    Resultados,
    GaleriaCarrera
  },
  data() {
    return {
      id: "",
      dialog: false,
      img: require("@/assets/Banner.jpg"),
      main: require("@/assets/principal.jpg"),
      icons: {
        run: require("@/assets/icons/run.png"),
        ubicacion: require("@/assets/icons/ubicacion.png"),
        distancia: require("@/assets/icons/distancia.jpg"),
        calendario: require("@/assets/icons/calendario.png"),
        pago: require("@/assets/icons/pago.png"),
        hashtag: require("@/assets/icons/hashtag.png"),
        ws: require("@/assets/icons/ws.png"),
        fb: require("@/assets/icons/fb.png"),
        in: require("@/assets/icons/in.png"),
        mail: require("@/assets/icons/mail.png")
      },
      res: { id: 0 },
      card: { id: 0, distancias: [] },
      foto: "",
      categorias: [], // yup
      distancias: [],
      imagenBanner: "",
      imagenVistaPrevia: "",
      imagenes: [],
      // nuevos
      imagenBanner1: { src: "", title: "", url: "" },
      imagenBanner2: { src: "", title: "", url: "" },
      imagenBanner3: { src: "", title: "", url: "" },
      imagenBanner4: { src: "", title: "", url: "" },
      imagenMiniBanner: { src: "", title: "", url: "" },
      auspiciantes: [],
      bannersPublicitarios: [],
      // nuevos banners proxima y organizada
      organizadasBanner1: { src: "", title: "", url: "" },
      proximasBanner1: { src: "", title: "", url: "" },

      // nuevo
      carrera: {
        id: 0,
        distancias: []
      },
      headers: [
        { text: "#", value: "lugar" },
        { text: "Camiseta", value: "numeroCamiseta" },
        { text: "Chip", value: "chipCronometraje.codigo" },
        { text: "Nombre", value: "persona.displayNombreCompleto" },
        /*
        {
          text: "Identificación",
          value: "persona.identificacion",
          align: "d-none"
        },
        */
        { text: "guntime", value: "guntime" },
        { text: "chiptime", value: "chiptime" },
        { text: "ritmo", value: "ritmo" }
      ]
    };
  },
  mounted() {
    // antes de crear el componente
    this.id = this.$route.params.id;
    //console.log(this.id);
    this.loadCarrera();
    this.loadAuspiciantes();
    this.loadBannersPublicitarios();
  },
  created() {
    //this.loadCarrera();
  },
  methods: {
    decimalToHour(value) {
      return DateUtils.decimalToHourDisplay(value);
    },
    openDialog() {
      //
      if (this.isLoggin) {
        this.dialog = true;
      } else {
        this.$router.push("/login");
      }
    },
    toCamelCase(value) {
      return StringUtils.toCamelCase(value);
    },
    formatDate(date) {
      moment.locale("es");
      let resp = moment(date).format(
        //"dddd d \\d\\e MMMM \\d\\e YYYY"
        "dddd DD \\d\\e MMMM \\d\\e YYYY - HH:mm"
      );
      resp =
        resp.substring(0, 1).toUpperCase() + resp.substring(1, resp.length);
      return resp;
    },
    share(rs) {
      const text = encodeURI(window.location.href);
      if (rs === "ws") return "https://wa.me/?text=" + text;
      else if (rs === "fb")
        return "https://www.facebook.com/sharer/sharer.php?u=" + text;
      else if (rs === "em") return text;
    },
    addToCart(value) {
      console.log(value);
      CartStore.addToCart(value);
      this.$router.push("/cart");
    },
    dialogChange(value) {
      this.dialog = value;
    },
    loadCarrera() {
      CarreraService.getCarreraOrganizada(this.$route.params.id)
        .then(response => {
          this.res = response;
          this.card = response;
          this.loadCategorias();
          this.loadDistancias();
          this.loadImagenVistaPrevia();
          this.carrera = response;
        })
        .catch(() => {
          console.log("no se pudo cargar la carrera");
        });
    },
    loadBannersPublicitarios() {
      BannerPublicitarioCarreraService.getBanners(this.$route.params.id)
        .then(response => {
          if (response.data) {
            this.bannersPublicitarios = response.data;
            this.loadImagen(); // banner
            this.loadImagenBanner(); // banner
            this.loadImagenes(); // banner
            this.loadImagenesPublicidad(); // banner
            this.loadProximasOrganizadasBanner();
          }
        })
        .catch(() => {
          console.log("no se cargo banners");
        });
    },
    loadAuspiciantes() {
      AuspicianteCarreraService.getBanners(this.$route.params.id)
        .then(response => {
          if (response.data) {
            this.auspiciantes = response.data;
          }
        })
        .catch(() => {
          console.log("no se cargo auspiciantes");
        });
    },
    getAuspicianteImageUrl(fileName) {
      return AuspicianteCarreraService.getImageUrl(fileName);
    },
    // helpers
    loadImagen() {
      const found = this.bannersPublicitarios.find(
        //element => element.name === "vistaPrevia"
        element => element.name === "imagenPrincipal"
      );
      if (found) {
        this.foto = BannerPublicitarioCarreraService.getImageUrl(
          found.fileName
        );
      }
    },
    loadImagenVistaPrevia() {
      const found = this.card.bannersPublicitarios.find(
        //element => element.name === "vistaPrevia"
        element => element.name === "vistaPrevia"
      );
      if (found) {
        this.imagenVistaPrevia = BannerPublicitarioCarreraService.getImageUrl(
          found.fileName
        );
      }
    },
    loadImagenBanner() {
      const found = this.bannersPublicitarios.find(
        element => element.name === "banner"
      );
      if (found) {
        this.imagenBanner = BannerPublicitarioCarreraService.getImageUrl(
          found.fileName
        );
      }
    },
    loadImagenes() {
      const found1 = this.bannersPublicitarios.find(
        element => element.name === "imagenCamiseta"
      );
      const found2 = this.bannersPublicitarios.find(
        element => element.name === "camisetaPersonalizada"
      );
      const found3 = this.bannersPublicitarios.find(
        element => element.name === "imagenMedalla"
      );
      const found4 = this.bannersPublicitarios.find(
        element => element.name === "imagenKit"
      );
      const found5 = this.bannersPublicitarios.find(
        element => element.name === "imagenCategorias"
      );
      const found6 = this.bannersPublicitarios.find(
        element => element.name === "productosAuspiciantes"
      );
      if (found1) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found1.fileName),
          title: "Camiseta Oficial"
        });
      }
      if (found2) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found2.fileName),
          title: "Personalice su Camiseta"
        });
      }
      if (found3) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found3.fileName),
          title: "Medalla Oficial"
        });
      }
      if (found4) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found4.fileName),
          title: "Kit"
        });
      }
      if (found5) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found5.fileName),
          title: "Categorias"
        });
      }
      if (found6) {
        this.imagenes.push({
          src: BannerPublicitarioCarreraService.getImageUrl(found6.fileName),
          title: ""
        });
      }
    },
    loadImagenesPublicidad() {
      const found1 = this.bannersPublicitarios.find(
        element => element.name === "publicidad1"
      );
      const found2 = this.bannersPublicitarios.find(
        element => element.name === "publicidad2"
      );
      const found3 = this.bannersPublicitarios.find(
        element => element.name === "publicidad3"
      );
      const found4 = this.bannersPublicitarios.find(
        element => element.name === "publicidad4"
      );
      const found5 = this.bannersPublicitarios.find(
        element => element.name === "miniBanner"
      );
      if (found1) {
        this.imagenBanner1 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found1.fileName),
          title: "",
          url: found1.url
        };
      }
      if (found2) {
        this.imagenBanner2 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found2.fileName),
          title: "",
          url: found2.url
        };
      }
      if (found3) {
        this.imagenBanner3 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found3.fileName),
          title: "",
          url: found3.url
        };
      }
      if (found4) {
        this.imagenBanner4 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found4.fileName),
          title: "",
          url: found4.url
        };
      }
      if (found5) {
        this.imagenMiniBanner = {
          src: BannerPublicitarioCarreraService.getImageUrl(found5.fileName),
          title: "",
          url: found5.url
        };
      }
    },
    loadProximasOrganizadasBanner() {
      const found1 = this.bannersPublicitarios.find(
        element => element.name === "proximasBanner1"
      );
      const found2 = this.bannersPublicitarios.find(
        element => element.name === "organizadasBanner1"
      );
      console.log(found2);
      if (found1) {
        this.proximasBanner1 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found1.fileName),
          title: "",
          url: found1.url
        };
      }
      if (found1) {
        this.organizadasBanner1 = {
          src: BannerPublicitarioCarreraService.getImageUrl(found2.fileName),
          title: "",
          url: found2.url
        };
      }
    },
    loadCategorias() {
      //AuthStore.getPersonaSexo
      //AuthStore.getPersonaEdad
      const categorias = [];
      if (AuthStore.loggedIn) {
        for (const v of this.card.categorias) {
          if (
            v.edadMinima > AuthStore.getPersonaEdad ||
            v.edadMaxima < AuthStore.getPersonaEdad
          ) {
            continue; // ignorados por no cumplir
          }
          if (
            v.tipoParticipante === "T" ||
            AuthStore.getPersonaSexo === v.tipoParticipante
          ) {
            categorias.push(v);
          }
        } //fin for
      }
      this.categorias = categorias;
    }, //fin funcion
    loadDistancias() {
      const distancias = [];
      if (AuthStore.loggedIn) {
        for (const v of this.card.distancias) {
          if (
            v.edadMinima > AuthStore.getPersonaEdad ||
            v.edadMaxima < AuthStore.getPersonaEdad
          ) {
            continue; // ignorados por no cumplir
          } else {
            distancias.push(v);
          }
        } //fin for
      }
      this.distancias = distancias;
    }
  },
  computed: {
    isLoggin() {
      return AuthStore.getLoggedIn;
    },
    distanciasJoin() {
      const distancias = [...this.card.distancias];
      distancias.sort(function(a, b) {
        // asc
        if (a.position > b.position) return 1; // 1 y -1 desc
        if (a.position < b.position) return -1; // 1 y -1 asc
        return 0;
      });
      return distancias.map(v => v.nombre).join(" - ");
    }
  }
};
</script>
<style scoped>
.title-item {
  color: #5a897d;
}
.pd {
  padding-left: 6px;
  padding-right: 6px;
}
</style>
