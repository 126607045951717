












































































import Vue from "vue";
import TablaResultados from "./TablaResultados.vue";
export default Vue.extend({
  props: ["distancia"],
  components: {
    TablaResultados
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    isCategoriaOnlyNombre(): boolean {
      if (this.$route.params.id === "9") {
        return true;
      }
      return false;
    }
  }
});
