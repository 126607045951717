









































import Vue from "vue";
import CarreraService, { GetResultadoCarrera } from "@/api/CarreraService";
import DistanciaCategorias from "@/components/web/organizadas/resultados/DistanciaCategorias.vue";
// pedir id de carrera, categorias y distancias
export default Vue.extend({
  props: ["distancias"],
  components: {
    DistanciaCategorias
  },
  data() {
    return {
      tab: null,
      resultados: [] as GetResultadoCarrera[]
    };
  },
  methods: {}
});
