<template>
  <v-row v-if="fotos && fotos.length > 0">
    <v-col cols="12" class="text-center title-item">
      <div>
        <h2>Galeria</h2>
      </div>
    </v-col>
    <v-col cols="12"> </v-col>
    <v-col cols="12">
      <v-card>
        <v-row v-masonry transition-duration="0.3s" item-selector=".item">
          <v-col
            class="item"
            v-for="(item, index) in fotos"
            :key="index"
            cols="12"
            sm="4"
          >
            <v-card height="100%" color="">
              <a :href="item.images['original']" target="_blank">
                <v-img
                  :lazy-src="item.images['thumbnail']"
                  :src="item.images['medium']"
                  @load="repaint"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#5a897d"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </a>
            </v-card>
            <!-- block item markup -->
          </v-col>
        </v-row>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
//import * as easings from "vuetify/lib/services/goto/easing-patterns";
//import CarouselTriple from "@/components/web/proximo/id/recorrido/CarouselTriple.vue";
import GaleriaCarreraService from "@/api/carrera/detalle/GaleriaCarrera.service";
import { GetPaginateDto } from "@/api/interfaces";

export default {
  components: {
    //CarouselTriple
  },
  data() {
    return {
      fotoPrincipal: "",
      fotos: [],
      galeria: {
        items: [],
        meta: {
          totalItems: 0,
          itemCount: 0,
          itemsPerPage: 10,
          totalPages: 0,
          currentPage: 1
        }
      }
    };
  },
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.galeria.meta.currentPage < this.galeria.meta.totalPages) {
        this.galeria.meta.currentPage++;
        this.loadGaleria();
      }
    },
    loadGaleria() {
      const params = new URLSearchParams({
        page: this.galeria.meta.currentPage.toString(),
        limit: "10"
      }).toString();

      GaleriaCarreraService.getAllPaginado(this.$route.params.id, params)
        .then(respuesta => {
          this.galeria = respuesta;
          this.fotos.push(...respuesta.items);
        })
        .catch(() => {
          //
        });
    },
    repaint() {
      this.$nextTick(() => this.$redrawVueMasonry());
    },
    onScroll() {
      //this.repaint();
    },
    selectPrincipal(item, focus = false) {
      if (item && item.url) {
        this.fotoPrincipal = item.url;
        if (focus) {
          this.focusPrincipal();
        }
      }
    },
    focusPrincipal() {
      //this.$refs.imagenPrincipal.$el.focus();
      //this.$vuetify.goTo("#imagenPrincipalID");
    }
  },
  mounted() {
    this.loadGaleria();
    this.repaint();
  },
  watch: {
    fotos: function() {
      this.repaint();
    }
  }
};
</script>

<style scoped>
.title-item {
  color: #5a897d;
}
</style>
